@tailwind base;
@tailwind components;
@tailwind utilities;


.text-primary {
  color: #3e2083 !important;
}

.bg-primary {
  background-color: #3e2083 !important;
}
.btn-link{
  color: #3e2083 !important;
}

/* Now you can use var(--primary-color) to refer to the primary color throughout your CSS */




@media (min-width: 1025px) {
    .h-custom {
    height: 100vh !important;
    }
    }
    .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    /* padding-left: .75em; */
    /* padding-right: .75em; */
    }
    .card-registration .select-arrow {
    top: 13px;
    }
    
    .gradient-custom-2 {
    /* fallback for old browsers */
    background: #a1c4fd;
    
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1));
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1))
    }
    
    .bg-indigo {
    background-color: #4835d4;
    }
    @media (min-width: 992px) {
    .card-registration-2 .bg-indigo {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    }
    }
    @media (max-width: 991px) {
    .card-registration-2 .bg-indigo {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    }
    }

    .btn-custom {
       
        background-color: #4caf50; 
        color: #fff; 
        transition: background-color 0.3s ease; /* Smooth transition effect */
      }
      
      .btn-custom:hover {
        background-color: #216426f8; 
        color: #fff; 
      }

    

    .form-select{
        color: #332d2d;
    }
    .form-label{
        color: #332d2d;
        font-size: 19px;
    }

    .response-box {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      z-index: 999;
      max-width: 80%;
      width: 400px;
    }
    
    .response-content {
      font-size: 16px;
      line-height: 1.5;
      color: #333;
      white-space: pre-line;
    }
    
    .response-box button {
      margin-top: 20px;
      cursor: pointer;
      background-color: #e74c3c;
      color: #fff;
      border: none;
      padding: 10px 15px;
      border-radius: 4px;
      transition: background-color 0.3s ease;
    }
    
    .response-box button:hover {
      background-color: #c0392b;
    }
    



    
    
    @keyframes moveBg {
      from { background-position: 0 50%; }
      to { background-position:  -1134px 50%; }
    }
    
    .carousel_home {
      /* --blur: 6px; */
      /* --contrast: 105%; */
      --speed: 13s;
      height: 210px;
      max-width: 1300px;
      width: 100%;
      position: relative;
    
      .mask_home {
        position: absolute;
        inset: 0;
        background: #0000;
        backdrop-filter: blur(var(--blur)) contrast(var(--contrast));
        -webkit-backdrop-filter: blur(var(--blur)) contrast(var(--contrast)); /* so it works on Safari */
        -webkit-mask: linear-gradient(90deg, #000 50px, #0000 175px calc(100% - 175px), #fff calc(100% - 50px));
        pointer-events: none;
      }
    
      .logos_home {
        animation: moveBg var(--speed) linear infinite;
        position: absolute;
        inset: 0;
        background: url(../Images/as4.webp) 0 80% / 1200px 200px repeat-x;
        /* -webkit-mask: linear-gradient(90deg, #0000 5px, #000 50px calc(100% - 50px), #0000 calc(100% - 5px)); */
      }
    }
    
    
    /* make the second carousel larger icons and more blur*/
    .carousel_home:nth-of-type(2) {
      /* --blur: 9px; */
      /* --contrast: 125%; */
    
      .logos {
        background: url(../Images/as4.webp) 0 80% / 1200px 150px repeat-x;
      }
    }